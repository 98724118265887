window.CartService = function (_settings) {
  "use strict";
  const _VERSION = ' Incograf Cart js API :: 1.0.01';
  const _COPYRIGHT = `
       Incograf, LDA                            \n
                                                \n
       Contacts: suporte@incograf.com           \n
       Address :                                \n
       Apt 68 . Avenida Dr. Seabra Dinis, Nº187 \n
       Bl.2 r/c-dto                             \n
       3780-111 Sangalhos                       \n
       PORTUGAL                                 \n`;
  const _self = this;
  let settings;
  let isDebugger = false;
  let xhrOptions = {
    method: "GET",
    mode: 'cors',
    cache: 'default'
  };

  const log = function (vardata) {
    if (isDebugger) {
      console.log(vardata);
    }
  }
  this.VERSION = function () {
    window.console.log(_COPYRIGHT + _VERSION);
  };

  this.getMeta = function (metaName) {
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        return metas[i].getAttribute('content');
      }
    }
    return '';
  }

  this.constructor = function (_settings) {
    this.VERSION();

    this.xhrOptions = {
      method: "GET",
      headers: {
        "X-CSRF-Token": _self.getMeta('csrf-token')
      },
      mode: 'cors',
      cache: 'default'
    };

    if (_settings !== undefined) {
      var required = ['endPointCart'];
      if (isValidParams(_settings, required)) {
        this.settings = _settings;
        return;
      }
      log("missing parameters");
    }
  }

  this.constructor(_settings);

  this.mountXhrOptions = function(method, data){
    _self.xhrOptions.method = method;

    if (data !== undefined) {
      _self.xhrOptions.body = data;
      _self.xhrOptions.headers['Accept'] = 'application/json';
      _self.xhrOptions.headers['Content-Type'] = 'application/json';
    } else {
      delete _self.xhrOptions.body;
    }
  }
  this.asyncFetchData = async function (entryPoint ,method, data) {
    const fullurl = _self.settings.endPointCart + entryPoint;
    _self.mountXhrOptions(method, data);
    const response = await fetch(fullurl, _self.xhrOptions);
    const jsonData = await response.json();
    return jsonData;
  }

  this.fetchData = function (entryPoint ,method, data, successCallback, failedCallback) {
    const fullurl = _self.settings.endPointCart + entryPoint;
    _self.mountXhrOptions(method, data);

    return fetch(fullurl,_self.xhrOptions)
      .then( async function(response) {

        if (response.status >= 400){
          return response.json().then(json => { throw new Error(json.message) });
        }
        return response.json();
      })
      .then((response)=> {
        window.localStorage.setItem('cartService', JSON.stringify(response));
        if (typeof successCallback === "function") {
          successCallback(response);
        }
      })
      .catch((response) => {
        if (typeof failedCallback === "function") {
          failedCallback(response);
        }

      });

  }

  function isValidParams(arr, arrRequired) {
    for (var key in arrRequired) {
      if (!arr.hasOwnProperty(arrRequired[key]))
        return false;
    }
    return true;
  }

  return {
    get : function (successCallback, failedCallback) {
      return _self.fetchData('/cart/', 'GET', undefined , successCallback, failedCallback );
    },
    update : function (data ,successCallback, failedCallback) {
      return _self.fetchData('/cart/update', 'POST',JSON.stringify(data),  successCallback, failedCallback );
    },
    asyncUpdate : async function (data) {
      return await _self.asyncFetchData('/cart/update', 'POST',JSON.stringify(data));
    },
    checkout : function (data ,successCallback, failedCallback) {
      return _self.fetchData('/cart/checkout', 'POST',JSON.stringify(data),  successCallback, failedCallback );
    },
    shipping : function (data ,successCallback, failedCallback) {
      return _self.fetchData('/cart/shipping', 'POST',JSON.stringify(data),  successCallback, failedCallback );
    },
    clear : function (successCallback, failedCallback) {
      return _self.fetchData('/cart/clear', 'POST', undefined,  successCallback, failedCallback );
    }
  }
}

window.cart = new window.CartService({'endPointCart':`${baseUrl}/service`});
